



























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { deleteApikey, getApikeys } from '@/api/consoleApi/developers'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import { copyToClipboard } from '@/utils/copyToClipboard'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsCreateApikeyModal from '../components/VsCreateApikeyModal/Index.vue'

@Component({
    name: 'ApikeyDevelopers',
    components: {
        VsFormGroup,
        VsLoader,
        VsTable,
        VsConfirm,
        VsCreateApikeyModal,
    },
})
export default class extends Vue {
    private loading = false
    apikeys = []

    private filters = {
        search: '',
    }

    private pagination: any = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        orderBy: null,
    }

    $refs: any

    get user () {
        return UserModule.user
    }

    get tableHeaders (): TableHeader[] {
        return [
            {
                id: 'api_key',
                key: 'api_key',
                label: 'API key',
                visible: true,
            },
            {
                id: 'note',
                key: 'note',
                label: 'Note',
                visible: true,
            },
            {
                id: 'ip_address',
                key: 'ip_address',
                label: 'Indirizzo IP',
                formatter: (value: any) => {
                    return value || '-'
                },
                visible: true,
            },
        ]
    }

    beforeMount () {
        this.getApikeys()
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.currentPage = 1
        this.getApikeys()
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getApikeys () {
        try {
            this.loading = true
            const resp = await getApikeys(this.buildParams())
            this.apikeys = resp.data.data
            this.pagination.totalItems = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.apikeys = []
            this.pagination.totalItems = 0
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
            search: this.buildSearch(),
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `note:${this.filters.search}` : '',
            this.filters.search.trim() !== '' ? `ip_address:${this.filters.search}` : '',
            this.filters.search.trim() !== '' ? `api_key:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private copyToClipboard (string: string) {
        try {
            copyToClipboard(string)
            this.$root.$vsToast({
                heading: this.$t('developers.apikey.apiCopiedSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('developers.apikey.apiCopiedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async deleteApikey (id: string) {
        try {
            await this.$refs.vsConfirmDeleteApikey.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteApikey(id)
            await this.getApikeys()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'API key eliminata con successo',
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dell\' API key',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }
}
