import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const createApikey = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/apikeys/`,
        data,
        {
            cancelToken,
        },
    )

export const getApikeys = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/apikeys`,
        {
            cancelToken,
            params,
        },
    )

export const deleteApikey = (apikeyId: string | number, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/apikeys/${apikeyId}`,
        {
            cancelToken,
        },
    )
