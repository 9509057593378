








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsFormGroup',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: '',
    }) tooltip?: string
}
