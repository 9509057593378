
































































import { Component, Vue } from 'vue-property-decorator'
import { createSuppression } from '@/api/consoleApi/suppressions'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { createApikey } from '@/api/consoleApi/developers'

@Component({
    name: 'VsCreateApikeyModal',
})
export default class extends Vue {
    private show = false
    private loading = false
    private ip = ''
    private note = ''
    $refs: any

    get ipAddress () {
        return /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/i
    }

    public async openModal () {
        this.loading = false
        this.note = ''
        this.ip = ''
        this.show = true
    }

    public closeModal () {
        this.show = false
    }

    private async handleSubmit () {
        const success = await this.$refs.apikeyForm.validate()
        if (!success) return
        this.loading = true
        try {
            const data = {
                note: this.note,
                ip: this.ip,
            }
            await createApikey(data)
            this.$emit('apikey-created')
            this.$root.$vsToast({
                heading: 'API Key creata correttamente',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la creazione dell\'API Key',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}
